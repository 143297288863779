import React from 'react'
import ReactDOM from 'react-dom'
import { setActionMap } from '@lib/data-collector'
import App from './routers'
import { ConfigProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import './assets/styles/base.less'
import './assets/styles/iconfont.css'
import { createHashHistory } from 'history'
const history = createHashHistory()
export {
  history
}
setActionMap({
    'homeExposure': {
        gtp: '6.d3a0.0.0',
        eid: '0',
        gtype: 'p',
    },
    'noviceExposure': {
        gtp: '6.fb3d.0.0',
        eid: '0',
        gtype: 'p',
    },
    'rulesExposure': {
        gtp: '6.33ce.0.0',
        eid: '0',
        gtype: 'p',
    },
    'courseExposure': {
        gtp: '6.d2e5.0.0',
        eid: '0',
        gtype: 'p',
    },
    'courseDetailsExposure': {
        gtp: '6.fee4.0.0',
        eid: '0',
        gtype: 'p',
    },
    'entryPocessExposure': {
        gtp: '6.p858.0.0',
        eid: '0',
        gtype: 'p',
    },
})
ReactDOM.render(
    <ConfigProvider locale={zh_CN}>
        <App/>
    </ConfigProvider>,
    document.getElementById('root')
)