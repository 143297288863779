import React, { Suspense } from 'react';
import { HashRouter as Router, Switch, Route} from 'react-router-dom';

const Main = React.lazy(() => import('@/containers/main')) //主页
const NoFound = React.lazy(() => import('@/containers/noFound')) //404

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
            <Route path='/' component={Main}></Route>
            <Route component={NoFound}></Route>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
